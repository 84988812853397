@import-normalize;
@import url('https://fonts.googleapis.com/css?family=Merriweather:400,400i,700');

body {
  font-family: Merriweather, serif;
  margin: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
